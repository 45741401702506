@font-face {
  font-family: "NotoSansJP";
  src: local('NotoSansJP'), url("../assets/font/NotoSansJP/NotoSansJPRegular.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansJP";
  src: local('NotoSansJP'), url("../assets/font/NotoSansJP/NotoSansJPMedium.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansJP";
  src: local('NotoSansJP'), url("../assets/font/NotoSansJP/NotoSansJPBold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansJP";
  src: local('NotoSansJP'), url("../assets/font/NotoSansJP/NotoSansJPBlack.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

:root {
  --font: 'NotoSansJP', 'sans-serif';
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  font-family: 'NotoSansJP', 'sans-serif';
}

img {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font-family: var(--font);
  font-size: 1.4rem;
  font-weight: 300;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

button {
  cursor: pointer;
}

body {
  font-family: var(--font);
  font-size: 1.2rem;
  overflow-x: hidden;
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html,
body,
#root {
  height: 100%;
  overflow-y: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modal-open {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

.int-lession {
  justify-content: center;
  margin-top: -35px;
  margin-left: -220px;

  @media screen and (max-width: 1199px) {
    margin-top: 0;
    margin-left: -185px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0;
    margin-left: 0;
  }

  .nav-item {
    color: rgb(255, 255, 255);
    font-size: 14px;
    overflow: hidden;
    padding-right: 7px;
    width: auto; // 126px;
    text-align: left;
    border: none;
    width: 750px;

    strong {
      font-weight: normal !important;
      padding: 0 5px;
      // border-right: 1px solid;
      // &:last-child {
      //   border-right: none;
      // }
    }
  }
}

.infheader-custom-height {
  padding: 1.38rem 0 !important;
}

.inf-custom-height {
  height: 54px !important;
}


.custom-btn-stl-sub {
  background-color: transparent;
  background: radial-gradient(50% 50% at 50% 50%, #38D7D0 0%, #00B7AF 100%);
  color: #fff;
  width: 194px;
  height: 44px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #fff;
  }

  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.5s ease-out 0s;
}

.cs-main-mode-btn {
  button {
    font-size: 17px !important;
    width: 280px;
    height: 55px;
  }
}

.scoring__item--input {
  :read-only {
    background: none !important;
  }
}

.cssd-before-prn {
  background: linear-gradient(90deg, rgba(56, 215, 208, 0.45) 0%, rgba(56, 215, 208, 0.36) 0.01%, rgba(67, 221, 214, 0.09) 91.15%) !important;

}
.modal-open .modal{
  pointer-events: none;
}
.fs_20px{
  font-size: 20px !important;
}