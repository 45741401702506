
 @keyframes dotLoading {
  to {
    opacity: 0;
  }
}

.dots-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  > div {
    width: .8rem;
    height: .8rem;
    border-radius: 1rem;
    background-color: #000;
    margin: 0 1rem;
    animation: dotLoading 1s forwards infinite linear;
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
  }
}

.modal-backdrop {
  opacity: 0 !important;
}

#printWindow {
  display: none;
}
